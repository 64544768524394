var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('LoadingState'):_c('div',{staticClass:"resultTeacher"},[_c('div',{staticClass:"coverDetail"},[_c('h2',[_vm._v(" "+_vm._s(_vm.detailCbt.tipe)+" "+_vm._s(_vm.detailCbt.tingkat)+" | "+_vm._s(_vm.detailCbt.mapel)+" ")]),_c('div',[_vm._v(_vm._s(_vm.detailCbt.waktu_ujian)+" | "+_vm._s(_vm.detailCbt.tipe_cbt))])]),_c('div',{staticClass:"contentDetail"},[_c('div',{staticClass:"detail-slot"},[_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('div',{staticClass:"d-lg-flex flex-wrap mb-3"},[_c('a-select',{ref:"selectClass",class:['d-block', !_vm.isDesktop ? 'mb-3' : ''],style:({
                width: _vm.isDesktop ? '140px' : '',
                height: _vm.isDesktop ? '48px' : '',
              }),attrs:{"size":"large","placeholder":"Select Class","default-value":"All Class"},on:{"change":_vm.handleSelectClass}},[_c('a-select-option',{attrs:{"value":""}},[_vm._v("All Class")]),_vm._l((_vm.classes),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.tingkat)+"-"+_vm._s(item.simbol))])})],2),_c('a-form-item',{staticClass:"d-block d-lg-none"},[_c('a-select',{staticStyle:{"width":"100%","height":"40px"},attrs:{"value":_vm.selectView,"size":"large"},on:{"change":function (e) { return (_vm.selectView = e); }}},[_c('a-select-option',{attrs:{"value":"Grade"}},[_vm._v(_vm._s(_vm.textRadio1))]),_c('a-select-option',{attrs:{"value":"Analytics"}},[_vm._v(_vm._s(_vm.textRadio2))])],1)],1),_c('a-form-item',{staticClass:"d-none d-lg-block"},[_c('a-radio-group',{staticClass:"ml-2",staticStyle:{"height":"48px"},attrs:{"default-value":"Grade","button-style":"solid","size":"large"},on:{"change":_vm.handleSelectView}},[_c('a-radio-button',{attrs:{"value":"Grade"}},[_vm._v(_vm._s(_vm.textRadio1))]),_c('a-radio-button',{attrs:{"value":"Analytics"}},[_vm._v(_vm._s(_vm.textRadio2))])],1)],1),_c('a-button',{staticClass:"ml-lg-2",style:({
                'margin-top': _vm.isDesktop ? '' : '10px',
                height: '40px',
              }),attrs:{"type":"default","icon":"file-pdf","size":"large","block":!_vm.isDesktop},on:{"click":function($event){$event.preventDefault();return _vm.daftarHadirToPDF($event)}}},[_vm._v("Daftar Hadir")]),_c('a-button',{staticClass:"ml-lg-2",style:({
                'margin-top': _vm.isDesktop ? '' : '10px',
              }),attrs:{"type":"default","size":"large","icon":"file-excel","block":!_vm.isDesktop,"disabled":!_vm.dataTable.length || _vm.disableExport},on:{"click":function($event){$event.preventDefault();return _vm.exportToXslx($event)}}},[_vm._v("Daftar Nilai")]),_c('a-button',{staticClass:"ml-lg-2",style:({
                'margin-top': _vm.isDesktop ? '' : '10px',
              }),attrs:{"type":"default","size":"large","icon":"file-pdf","block":!_vm.isDesktop,"disabled":!_vm.dataTable.length || _vm.disableExport},on:{"click":function($event){$event.preventDefault();return _vm.exportBeritaAcara($event)}}},[_vm._v("Berita Acara")]),_c('a-button',{staticClass:"ml-lg-auto",style:({
                'margin-top': _vm.isDesktop ? '' : '10px',
                height: '40px',
              }),attrs:{"type":"default","block":!_vm.isDesktop},on:{"click":function($event){$event.preventDefault();return _vm.refreshTable($event)}}},[_c('a-icon',{attrs:{"type":"sync","spin":_vm.loadingRefresh}}),_vm._v("Refresh ")],1)],1),_c('a-drawer',{attrs:{"width":"500","placement":"left","closable":true,"visible":_vm.visibleDrawer,"title":"Computer Based Test Result"},on:{"close":_vm.onCloseDrawer}},[_c('p',[_vm._v("NIS : "+_vm._s(_vm.dataDrawer.nis))]),_c('a-divider'),_c('p',[_vm._v("Name : "+_vm._s(_vm.dataDrawer.Name))]),_c('a-divider'),_c('p',[_vm._v("Class : "+_vm._s(_vm.dataDrawer.Class))]),_c('a-divider'),_c('p',[_vm._v("Essay Grade : "+_vm._s(_vm.dataDrawer.EssayGrade))]),_c('a-divider'),_c('div',{staticClass:"d-flex flex-nowrap mb-5"},[_c('h3',{staticClass:"font-weight-bold text-dark my-auto"},[_vm._v(" Essay Answer : ")]),(!_vm.dataDrawer.EssayGrade && _vm.statusSlot)?_c('a-button',{staticClass:"ml-auto",attrs:{"size":"large","type":"primary","loading":_vm.loadingUpdateGrade},on:{"click":function($event){$event.preventDefault();return _vm.updateGrade(_vm.dataDrawer.key)}}},[_vm._v("Submit Grade")]):_vm._e()],1),_vm._l((_vm.dataDrawer.Soal),function(item,index){return _c('div',{key:index},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":2}},[_c('h3',[_vm._v(_vm._s(_vm.numberQuestion(index))+".")])]),_c('a-col',{attrs:{"span":22}},[_c('p',{staticClass:"mb-2",domProps:{"innerHTML":_vm._s(item.soal)}}),(_vm.dataDrawer.MultipleChoiceAnswer)?_c('div',{staticClass:"studentAnswer"},[_c('div',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Answer :")]),_c('span',{domProps:{"innerHTML":_vm._s(
                          _vm.answer(item.id, _vm.dataDrawer.MultipleChoiceAnswer)
                        )}})]),(!_vm.dataDrawer.EssayGrade && _vm.statusSlot)?_c('div',[_c('hr'),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.tipe_soal === 'Essay'),expression:"item.tipe_soal === 'Essay'"}],staticClass:"gradeEssay mt-1"},[_c('p',{staticClass:"font-weight-bold"},[_vm._v(" Grade (0 s/d "+_vm._s(item.bobot_soal)+") : "),_c('span',{class:_vm.handleGradeIndex(item.id) === 0
                                ? 'text-danger'
                                : ''},[_vm._v(_vm._s(_vm.handleGradeIndex(item.id)))])]),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":18}},[_c('a-slider',{attrs:{"value":_vm.handleGradeIndex(item.id),"min":0,"max":parseInt(item.bobot_soal),"step":parseInt(item.bobot_soal) / 10},on:{"change":function($event){return _vm.handleSlider($event, item.id)}}})],1),_c('a-col',{staticClass:"mt-1",attrs:{"span":4}},[_c('a-input-number',{attrs:{"value":_vm.handleGradeIndex(item.id),"min":0,"max":parseInt(item.bobot_soal),"step":parseInt(item.bobot_soal) / 10},on:{"change":function($event){return _vm.handleSlider($event, item.id)}}})],1)],1)],1)]):_vm._e()]):_vm._e()])],1),_c('hr')],1)})],2),(_vm.selectView === 'Grade')?_c('a-table',{staticClass:"tableGradeCbt",attrs:{"columns":_vm.detailCbt.tipe_cbt === 'Multiple Choice'
                ? _vm.columnsMultipleChoiceGrade
                : _vm.columnsMultipleChoiceEssayGrade,"data-source":_vm.dataTable,"loading":_vm.loadingTable,"pagination":_vm.pagination,"bordered":""},on:{"change":_vm.handleTable},scopedSlots:_vm._u([{key:"Name",fn:function(text, record){return _c('a',{staticClass:"text-primary",on:{"click":function($event){$event.preventDefault();return _vm.showDrawer(record.key)}}},[_vm._v(_vm._s(text))])}},{key:"TotalGrade",fn:function(text){return _c('span',{},[(text >= 70 && text <= 100)?_c('div',[_c('a-tag',{attrs:{"color":"green"}},[_vm._v(_vm._s(text))])],1):(text >= 35 && text < 70)?_c('div',[_c('a-tag',{attrs:{"color":"orange"}},[_vm._v(_vm._s(text))])],1):(text > 0 && text < 35)?_c('div',[_c('a-tag',{attrs:{"color":"red"}},[_vm._v(_vm._s(text))])],1):_vm._e()])}},{key:"Attendance",fn:function(text){return _c('span',{},[_c('p',{class:text === 'absent' ? 'text-danger' : 'text-success'},[_vm._v(" "+_vm._s(text.toUpperCase())+" ")])])}}],null,false,3131229347)},[_c('span',{attrs:{"slot":"customTitle"},slot:"customTitle"},[_c('a-icon',{attrs:{"type":"smile-o"}}),_vm._v("Name ")],1)]):_vm._e(),(_vm.selectView === 'Analytics')?_c('a-table',{staticClass:"multipleChoiceAnalysis",attrs:{"columns":_vm.columnsAnalytics,"data-source":_vm.dataTableAnalytics,"pagination":_vm.pagination,"loading":_vm.loadingTable,"scroll":{ x: 50, y: 500 },"size":"small","bordered":""},on:{"change":_vm.handleTable},scopedSlots:_vm._u([{key:"Soal",fn:function(text){return _c('span',{},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(_vm._s(text.charAt(2) === "C" ? "CORRECT" : text.charAt(2) === "W" ? "WRONG" : "Have not taken the exam yet"))]),_c('a-tag',{attrs:{"color":text.charAt(2) === 'C'
                      ? 'green'
                      : text.charAt(2) === 'W'
                      ? 'volcano'
                      : ''}},[_vm._v(_vm._s(text.charAt(0) === "H" ? "-" : text.charAt(0)))])],2)],1)}}],null,false,3355973823)},[_c('a-icon',{attrs:{"slot":"filterIcon","type":"search"},slot:"filterIcon"})],1):_vm._e()],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }